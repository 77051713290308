/*Fonts*/
@font-face {
  font-family: 'Yellowtail';
  src: local('Yellowtail'), url(./fonts/Yellowtail/Yellowtail-Regular.ttf) format('truetype');
}

/* Error page */
#errorContainer {
  height: 100vh;
  background: linear-gradient(to right, #0f4e5b, #206470)
}

#errorMessage {
  padding-top: 10%;
}

#homeButton {
  background: #fff;
  color: #0f4e5b;
}

/* Start React-datepicker override */
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper {
  z-index: 9 !important;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  margin-top: 0;
  color: #0f4e5b !important;
  font-weight: 400 !important;
  font-size: 1.25em !important;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range, .react-datepicker__quarter--selected, .react-datepicker__quarter--in-selecting-range, .react-datepicker__quarter--in-range {
  border-radius: 0.3em !important;
  background-color: #0f4e5b !important;
  color: #fff !important;
}

.react-datepicker {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif !important;
  font-size: 1em !important;
  background-color: #fff;
  color: #0f4e5b !important;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

/* end react-datepicker override */

/* Start Help Scout Beacon */

/* end Help Scout Beacon override */

/* Start PWA */
@media screen and (display-mode: standalone) {
  body, header, .sideNav {
    padding-top: 32px !important;
  }

  html, body {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: touch;
  }
}

/* End PWA */
